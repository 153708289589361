"use client";
import {
  FifthSection,
  FirstSection,
  Footer,
  FourthSection,
  LandingPageTabs,
  MainNav,
  SecondSection,
  ThirdSection,
} from "src/components";

export default function Home() {
  return (
    <div>
      <MainNav />
      <FirstSection />
      <LandingPageTabs />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <FifthSection />
      {/* <HelpSection /> */}
      <Footer />
    </div>
  );
}
